body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.main-container {
    padding-top: 65px;
    padding-bottom: 65px;
}

.simple-center {
    margin-left: 50% !important;
    transform: translate(-50%, 0);
}

.chart {
    height: 500px;
    min-width: 500px;
}

.users-email {
    max-width: 300px;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.text-break {
    white-space: break-spaces !important;
}

.row {
    justify-content: space-between;
}

.button-row {
    display: flex;
    justify-content: space-between;
}

.margins {
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.column.center-height {
    margin-top: auto;
    margin-bottom: auto;
}

.explorer-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

.media-view {
    width: 100%;
    object-fit: cover;
  }

.media-annotation-hierarchy-instance {
    margin-bottom: 8px;
    border-radius: 8px;
    padding: 4px;
    border: 2px solid #585858;
}
