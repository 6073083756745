.Sidebar {
    height: max(100vh, 100%);
    min-height: 100vh;
    border-radius: 0 !important;
}

.App {
    padding-bottom: 0 !important;
}

.Sidebar .logout_btn,
.Sidebar .version {
    display: flex;
    justify-content: center;
    margin: 10px 0 10px;
}

.Sidebar .version {
    color: rgba(255, 255, 255, .5);
    margin: 0;
}