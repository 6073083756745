.commentAreaAnnotation {
    resize: none;
    width: 100%;
    height: 150px;
}
.button-container {
    display: grid; /* Use CSS Grid */
    grid-template-columns: repeat(3, 1fr); /* Create 3 columns of equal width */
    gap: 10px; /* Add space between buttons */
}
.button-container button {
    /* Optional: additional styling for buttons */
    padding: 10px 10px;
}